
import emojiRegex from 'emoji-regex'
// 创建自定义指令
const noEmoji = {
  install: function (Vue) {
    Vue.directive('noEmoji', {
      // 当元素绑定到 DOM 时
      bind (el) {
        // 监听 input 事件
        el.addEventListener('input', function (event) {
          // 替换掉表情符号
          const cleanValue = event.target.value.replace(emojiRegex(), '')

          // 如果清理后的值和当前值不同，即需要更新输入框的值
          if (cleanValue !== event.target.value) {
            // 使用 setTimeout 避免无限递归调用
            setTimeout(() => {
              event.target.value = cleanValue

              // 触发 input 事件，以更新 v-model 数据（如果有绑定）
              const inputEvent = new Event('input', { bubbles: true })
              event.target.dispatchEvent(inputEvent)
            }, 0)
          }
        })
      }
    })
  }
}
export default noEmoji
