import Vue from 'vue'
// i18n国际化
import ElementUI, { Message } from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/assets/lang/index'
import axios from '@/assets/js/axiosExtend.js'
import VueAxios from 'vue-axios'
import qs from 'qs'

import '@/assets/js/dialogDrag'
import '@/assets/js/socket.io.js'
import '@/assets/js/adpter.js'
import '@/assets/js/webrtcClient.js'
import '@/assets/js/wp.js'
import 'babel-polyfill'
import creatWebsocket from '@/assets/js/common/creatWebsocket'

import '@/assets/bootstrap/dist/css/bootstrap.min.css'
import '@/assets/bootstrap/dist/js/bootstrap.min.js'

import * as Sentry from '@sentry/vue'
import '@/assets/css/googleMap.css'
import '@/assets/iconfont/iconfont.js'
import '@/assets/css/toast.css'

/*
 * 注册全局指令
 * 滚动加载
 */
import Scroll from '@/directive/scroll'
// 过滤特殊字符
import FilterSpecialChar from '@/directive/filterSpecialChar'
// 节流
import repeatClick from '@/directive/repeatClick'

import noEmoji from '@/directive/noEmoji'

import '@/directive/inputValScope'

// 气泡提示
import Toast from '@/assets/js/toast'

// 主题色
import '../theme/index.css'

// global css
import '@/assets/css/index.less'

import VueClipboard from 'vue-clipboard2'

import deviceInfo from '@/assets/js/common/handleDeviceInfo'

import filters from '@/assets/js/filters'
import { ServiceJumpCollect, TopHeader, TvuNotification } from 'tvu-common-ui'

Vue.use(Scroll)
Vue.use(FilterSpecialChar)
Vue.use(repeatClick)
Vue.use(noEmoji)
Vue.use(Toast)
Vue.use(ServiceJumpCollect)
Vue.use(TopHeader)

window.Vue = Vue
Vue.use(VueClipboard)
Object.keys(deviceInfo).forEach(key => Vue.filter(key, deviceInfo[key]))
// 遍历注册全局过滤器
Object.keys(filters).forEach(key => Vue.filter(key, filters[key]))
Vue.use(ElementUI, {
  i18n: (key, val) => i18n.t(key, val) // 用于elementUI插件的中英文翻译
})

// 定义一个新的Message方法，多传入一个offset参数
const $message = options => {
  return Message({
    ...options,
    offset: 55
  })
}

// 重写一遍success的方法,将offset写入options
;['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 55
      }
    }
    options.type = type
    return Message(options)
  }
})
// 将$message挂载到this上
Vue.prototype.$message = $message
Vue.prototype.$tvuNotification = TvuNotification

// 供集成的外部js使用
window.JSMessage = ElementUI.Message
window.JSConfirm = ElementUI.MessageBox

Vue.use(qs)
Vue.use(VueAxios, axios)
Vue.use(creatWebsocket)
Vue.config.productionTip = false

// 集成sentry 环境设置
let environmentTag = 'development'
let hostName = window.location.hostname
if (hostName.includes('cc-ms.tvunetworks.com')) {
  environmentTag = 'production-global'
} else if (hostName.includes('cc-ms.tvunetworks.cn')) {
  environmentTag = 'production-cn'
} else if (hostName.includes('cc-ms-test.tvunetworks')) {
  environmentTag = 'production-test'
}

environmentTag != 'development' &&
  Sentry.init({
    environment: environmentTag,
    Vue,
    dsn: 'https://6683006bc7a8497c83c851fe4b629cc2@o982456.ingest.sentry.io/4505185860255744',
    release: localStorage.getItem('newCCVersion'),
    beforeSend: (event, hint) => {
      const error = hint.originalException
      if (error && error.message) {
        if (
          error.message.includes(
            "Failed to execute 'send' on 'WebSocket': Still in CONNECTING state."
          )
        ) {
          return null
        }
        if (
          error.message.includes(
            'Non-Error promise rejection captured with keys: config, data, headers, request, status'
          )
        ) {
          return null
        }
      }
      event.ServerName = null
      return event
    },
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
      new Sentry.Replay()
    ],
    tracesSampleRate: 0.01,
    replaysSessionSampleRate: 0.01
  })

window.vm = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
